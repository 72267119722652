var Mustache = Mustache || {};
var generic = generic || {};
var SelectBox = SelectBox || {};
var site = site || {};

(function ($, SelectBox) {
  Drupal.behaviors.discover_block_v1 = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      var pathArray = window.location.pathname.split('/');
      const pageIndex = pathArray.indexOf('page');
      const page = pageIndex > 0 ? pathArray[pageIndex + 1] : '';
      var $thismodule = $('.js-discover-landing', context);

      $thismodule.each(function () {
        var $module = $(this);
        var $filter = $module.find('.js-discover-filter');
        var $blockA = $module.find('.discover-block.discover-block__a');
        var $blockB1 = $($module.find('.discover-block.discover-block__b')[0]);
        var $blockB2 = $($module.find('.discover-block.discover-block__b')[1]);
        var $blockC = $module.find('.discover-block.discover-block__c');
        var blockA = [];
        var blockB = [];
        var blockBMiddle = [];
        var blockBSecond = [];
        var blockC = [];
        var $currentBlockC = $blockC;
        var $currentSpinner = $currentBlockC.next('.js-discover-loading-spinner');
        var $olapicViewContainer = $module.find('.discover-olapic-viewer-container');
        var targetMet = false;
        var $menu = $module.find('.js-discover-navigation');
        var tileIteration = 0;
        var blockHtml;
        var partialHtml;
        var $productList;
        var $leftNav;
        var $rightNav;
        // Olapic Rearrange.
        var lastTileCount = 20;
        var lastIterationCount = 20;
        var $currentOlapicTile;
        var olapicViewer;
        var olapicViewCount = 0;
        var olapicViewCurrentIndex = 0;
        var olapicApiKey = '';
        var reOlapicTiles = [];
        var reNoneOlapicTiles = [];
        var $listContainer = $filter.find('.js-discover-filter__list_container');
        var $filterList = $listContainer.find('ul');

        function getOlapicTiles($block) {
          var $parent = $block ? $block : $module;
          var olapicTiles = [],
            $tiles = $parent.find('.js-discover-block__container');

          $.each($tiles, function (tile_index, tile_value) {
            var $tile = $(tile_value);

            if ($tile.hasClass('olapic_tile')) {
              olapicTiles.push($tile);
            }
          });

          return olapicTiles;
        }

        function checkForOlapicTile($imgtag) {
          if ($imgtag.siblings('.olapicimg').length > 0) {
            return true;
          }

          return false;
        }

        function processOlapicData(
          $imageUsed,
          $olapicTile,
          olapicMediaLocal,
          olapicImageCountLocal
        ) {
          var $imgtag = $imageUsed.find('img');
          var imgtagWidth = $imgtag.css('width');
          var imageurl = olapicMediaLocal[olapicImageCountLocal].images['original'];
          var styles = '';

          if (isMobile()) {
            imageurl = olapicMediaLocal[olapicImageCountLocal].images['mobile'];
            styles += 'height: ' + imgtagWidth + ';';
          }

          styles += 'background-image: url(' + imageurl.replace('https:', 'http:') + ');';
          if (checkForOlapicTile($imgtag)) {
            $imgtag.siblings('.olapicimg').css('height', imgtagWidth);
          } else {
            var $olapicdiv = $(
              '<div class="olapicimg js-olapicimg" style="' +
                styles +
                '" data-olapic-position="' +
                olapicImageCountLocal +
                '"></div>'
            );

            $imageUsed.append($olapicdiv).ready(function () {
              $imgtag.css('display', 'none');
              $olapicdiv.height($olapicdiv.width());
              $olapicdiv.once().on('click', function () {
                $currentOlapicTile = $olapicTile;
                olapicViewCurrentIndex = 0;
                openOlapicView(Number($(this).data('olapic-position')), olapicMediaLocal);
              });
            });
          }

          olapicImageCountLocal++;
          if (olapicImageCountLocal === olapicMediaLocal.length) {
            olapicImageCountLocal = 0;
          }
        }

        function containsTitle(array, name) {
          var contains = false;

          $.each(array, function (arr_index, arr_value) {
            if (arr_value['product_name'] === name) {
              contains = true;
            }
          });

          return contains;
        }

        function setEmptyDefaults() {
          return [
            {
              product_url: '',
              product_name: '',
              product_image: ''
            }
          ];
        }

        function returnformattedProduct(prod_value) {
          var prod = {};

          prod_value['product_url']
            ? (prod['product_url'] = prod_value.product_url)
            : (prod['product_url'] = '');
          prod_value['name']
            ? (prod['product_name'] = prod_value['name'])
            : (prod['product_name'] = '');
          prod_value['_embedded']['base_image']
            ? (prod['product_image'] = prod_value['_embedded']['base_image']['images']['normal'])
            : (prod['product_image'] = '');

          return prod;
        }

        function getOlapicMediaProducts(media) {
          var products = [];

          $.each(
            media['_embedded']['streams:all']['_embedded'].stream,
            function (prod_index, prod_value) {
              if (prod_value && prod_value.product_url !== null) {
                if (containsTitle(products, prod_value.name) === false) {
                  products.push(returnformattedProduct(prod_value));
                }
              }
            }
          );
          // Fail-safe should the Olapic API return no products.
          if (products.length === 0) {
            return setEmptyDefaults();
          }

          return products;
        }

        function getOlapicSocials(olapicMediaLocal, facebookAppID, index) {
          var media = olapicMediaLocal[index];
          var caption = encodeURIComponent(media['caption']);
          var deeplinkUrl = window.location.href.split('#')[0];

          return {
            facebook_url:
              'https://www.facebook.com/dialog/share?app_id=' +
              facebookAppID +
              ' &display=popup&href=' +
              deeplinkUrl,
            twitter_url: 'http://twitter.com/intent/tweet?text=' + caption,
            pinterest_url:
              'https://www.pinterest.com/pin/create/button/?url=' +
              deeplinkUrl +
              '&media=' +
              getOlapicMediaProducts(olapicMediaLocal[0])[0]['product_image']
          };
        }

        function getOlapicViewerData(index, olapicMediaLocal) {
          var $olapicTileData = $currentOlapicTile.find('.js-discover-block__olapicdata');

          if ($olapicTileData && $olapicTileData.length > 0) {
            var facebookAppID = $olapicTileData.data('facebook-appid');
            var olapic_one = {
              olapic_main_image: olapicMediaLocal[index].images['normal'],
              olapic_instagram_original_post: olapicMediaLocal[index].original_source,
              olapic_author_avatar: 'https://i.imgur.com/qYGRVeg.jpg',
              olapic_instagram_username: olapicMediaLocal[index]['_embedded'].uploader.name,
              olapic_photo_caption: olapicMediaLocal[index]['caption'],
              olapic_post_media_id: olapicMediaLocal[index]['id'],
              olapic_products: getOlapicMediaProducts(olapicMediaLocal[index]),
              olapic_social: getOlapicSocials(olapicMediaLocal, facebookAppID, index),
              blog_article_metadata_olapic_share: $olapicTileData.data('share-text'),
              blog_article_metadata_olapic_shop_look: $olapicTileData.data('shop-look'),
              blog_article_metadata_olapic_avatar_alt: $olapicTileData.data('avatar-alt'),
              blog_article_metadata_olapic_report_text: $olapicTileData.data('report-text'),
              blog_article_metadata_olapic_email_placeholder:
                $olapicTileData.data('email-placeholder'),
              blog_article_metadata_olapic_email_reason: $olapicTileData.data('email-reason'),
              blog_article_metadata_olapic_email_cancel: $olapicTileData.data('email-cancel'),
              blog_article_metadata_olapic_email_report: $olapicTileData.data('email-report'),
              blog_article_metadata_olapic_close: $olapicTileData.data('close-text'),
              blog_article_metadata_olapic_report_okay: $olapicTileData.data('report-okay'),
              blog_article_metadata_olapic_report_fail: $olapicTileData.data('report-fail'),
              blog_article_metadata_olapic_report_email_invalid:
                $olapicTileData.data('email-invalid')
            };

            return olapic_one;
          }

          return {};
        }

        function moveOlapicViewCarouselLeft(olapicMediaLocal) {
          olapicViewCurrentIndex--;
          if (olapicViewCurrentIndex === -1) {
            olapicViewCurrentIndex = olapicViewCount - 1;
          }
          openOlapicView(olapicViewCurrentIndex, olapicMediaLocal);
        }

        function moveOlapicViewCarouselRight(olapicMediaLocal) {
          olapicViewCurrentIndex++;
          if (olapicViewCurrentIndex === olapicViewCount) {
            olapicViewCurrentIndex = 0;
          }
          openOlapicView(olapicViewCurrentIndex, olapicMediaLocal);
        }

        function isEmail(email) {
          var regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

          return regex.test(email);
        }

        function reportImagery(viewData) {
          var reportEmail = $olapicViewContainer.find('input[name=email]').val(),
            reportReason = encodeURIComponent(
              $olapicViewContainer.find('input[name=reason]').val()
            ),
            validEmail = isEmail(reportEmail),
            $invalidMessage = $olapicViewContainer.find('#reportInvalidEmail');

          if (!validEmail) {
            $invalidMessage.addClass('showing');
          } else {
            $.ajax({
              url:
                'https://photorankapi-a.akamaihd.net/media/' +
                viewData.olapic_post_media_id +
                '/reports?version=v2.2&auth_token=' +
                olapicApiKey +
                '&email=' +
                reportEmail +
                '&reason=' +
                reportReason,
              context: document.body
            }).done(function () {
              $olapicViewContainer.find('.form-group.report-buttons').addClass('hiding');
              $invalidMessage.removeClass('showing');
              $olapicViewContainer.find('#reportOK').addClass('showing');
            });
          }
        }

        function resetReportElements($reportWrapper) {
          $reportWrapper.removeClass('open');
          $olapicViewContainer.find('.reportPhotoTextHide').removeClass('showing');
          $olapicViewContainer.find('.form-group.report-buttons').removeClass('hiding');
        }

        function setOlapicViewerListeners(viewData, olapicMediaLocal) {
          var $reportWrapper = $olapicViewContainer.find('.olapic-report-wrapper');

          $olapicViewContainer
            .find('.viewer-close')
            .once()
            .on('click', function () {
              olapicViewCurrentIndex = 0;
              $olapicViewContainer.find('#olapicTemplatesArea').removeClass('showing');
            });
          $olapicViewContainer
            .find('#viewer-prev')
            .once()
            .on('click', function () {
              moveOlapicViewCarouselLeft(olapicMediaLocal);
            });
          $olapicViewContainer
            .find('#viewer-next')
            .once()
            .on('click', function () {
              moveOlapicViewCarouselRight(olapicMediaLocal);
            });
          $olapicViewContainer
            .find('.report-photo')
            .once()
            .on('click', function () {
              if ($reportWrapper.hasClass('open')) {
                $reportWrapper.removeClass('open');
              } else {
                $reportWrapper.addClass('open');
              }
            });
          $olapicViewContainer
            .find('.cancel-report')
            .once()
            .on('click', function () {
              resetReportElements($reportWrapper);
            });
          $olapicViewContainer
            .find('.close-report')
            .once()
            .on('click', function () {
              resetReportElements($reportWrapper);
            });
          $olapicViewContainer
            .find('.submit-report')
            .once()
            .on('click', function () {
              reportImagery(viewData);
            });
        }

        function setNavigationListeners(productCount) {
          var navCounter = 0;

          $rightNav.once().on('click', function () {
            navCounter++;
            var dist = 170;

            if ($productList.find('li').length > 0) {
              dist = $($productList.find('li')[0]).outerWidth(true);
            }
            $productList.css('margin-left', '-' + navCounter * dist + 'px');

            if (navCounter === productCount - 1) {
              $(this).addClass('hiding');
            }
            if (navCounter > 0) {
              $leftNav.removeClass('hiding');
            }
          });

          $leftNav.once().on('click', function () {
            navCounter--;
            var dist = 170;

            if ($productList.find('li').length > 0) {
              dist = $($productList.find('li')[0]).outerWidth(true);
            }
            $productList.css('margin-left', '-' + navCounter * dist + 'px');
            if (navCounter === 0) {
              $(this).addClass('hiding');
            }
            if (navCounter < productCount - 1) {
              $rightNav.removeClass('hiding');
            }
          });
        }

        function configureProductCarousel(viewData) {
          var productCount = viewData.olapic_products.length;

          if (productCount > 2) {
            var carouselWidth = productCount * 170;

            $productList = $olapicViewContainer.find('.products-list');
            $leftNav = $olapicViewContainer.find('.products-previous');
            $rightNav = $olapicViewContainer.find('.products-next');
            $productList.width(carouselWidth);
            $rightNav.removeClass('hiding');
            setNavigationListeners(productCount);
          }
        }

        function openOlapicView(index, olapicMediaLocal) {
          if (!index) {
            index = 0; // IE workaround. Doesn't allow default parameters.
          }
          $olapicViewContainer.empty(); // Garbage collection
          var viewData = getOlapicViewerData(index, olapicMediaLocal);
          var rendered = Mustache.render(olapicViewer, viewData);

          $olapicViewContainer.html(rendered);
          configureProductCarousel(viewData);
          setOlapicViewerListeners(viewData, olapicMediaLocal);
          $olapicViewContainer.find('#olapicTemplatesArea').addClass('showing');
        }

        function getImageUsed($currentImages) {
          var usedImage;

          $.each($currentImages, function (img_index, img_value) {
            var $image = $(img_value);

            if ($image.css('display') !== 'none') {
              usedImage = $image;
            }
          });

          return usedImage;
        }

        function checkForOlapicImagery(olapicMediaLocal, olapicImageCountLocal, $block, $tile) {
          var $olapicTile = $tile,
            $currentImages = $olapicTile.find('picture'),
            $imageUsed = getImageUsed($currentImages);

          $imageUsed
            .siblings('.discover-block__olapic--content')
            .html(olapicMediaLocal[olapicImageCountLocal].caption);
          processOlapicData($imageUsed, $olapicTile, olapicMediaLocal, olapicImageCountLocal);
        }

        function getOlapicData($block) {
          var $olapicTiles = getOlapicTiles($block);

          $.each($olapicTiles, function (ol_data_index, ol_data_value) {
            var $tile = $(ol_data_value),
              $dataEl = $tile.find('.js-discover-block__olapicdata'),
              streamID = $dataEl.data('stream-id'),
              apiID = $dataEl.data('api-key'),
              olapicImageCountLocal = 0;

            if (streamID && apiID) {
              $.getJSON(
                'https://photorankapi-a.akamaihd.net/streams/' +
                  streamID +
                  '/media/recent?rights_given=true&auth_token=' +
                  apiID +
                  '&version=v2.2',
                function (json_data) {
                  var olapicMediaLocal = json_data.data['_embedded'].media;

                  olapicViewCount = olapicMediaLocal.length;
                  checkForOlapicImagery(olapicMediaLocal, olapicImageCountLocal, $block, $tile);
                }
              );
            }
          });
        }

        function getOlapicTileData($block) {
          $.each($block.children(), function (tile_index, tile_value) {
            var $tile = $(tile_value);

            if ($tile.hasClass('olapic_tile')) {
              reOlapicTiles.push($tile);
            } else {
              reNoneOlapicTiles.push($tile);
            }
          });
        }

        function clearBlock($block) {
          $.each($block.children(), function (tile_index, tile_value) {
            $(tile_value).remove();
          });
        }

        function addNonOlapicTiles($block, isBlockB, isBlockC) {
          var range = isBlockB ? 4 : 3;

          if (isBlockC && isMobile()) {
            range = 2;
          }
          for (var i = 0; i < range; i++) {
            if (reNoneOlapicTiles.length > 0) {
              $block.append(reNoneOlapicTiles[0]);
              reNoneOlapicTiles.shift();
            }
          }
        }

        function addOlapicTiles($block, isBlockB) {
          var expectedTiles = isBlockB ? 4 : 3;
          var dudeTilesCount = expectedTiles - $block.children().length;

          if (reOlapicTiles.length > 0) {
            for (var i = 0; i < dudeTilesCount; i++) {
              $block.append(
                $(
                  '<div class="discover-block__container js-discover-block__container standard_tile"></div>'
                )
              );
            }
            $block.append(reOlapicTiles[0]);
            reOlapicTiles.shift();
          }
        }

        function finishBlock($block) {
          if (reNoneOlapicTiles.length > 0) {
            $block.append(reNoneOlapicTiles[0]);
            reNoneOlapicTiles.shift();
          }
        }

        function populateRemainingTiles($blockRef, position_index) {
          var popCount = 0;

          $.each(reNoneOlapicTiles, function (tile_index, $tile) {
            if (tile_index < position_index) {
              $blockRef.append($tile);
              popCount++;
            }
          });
          for (var i = 0; i < popCount; i++) {
            reNoneOlapicTiles.shift();
          }
        }

        function rearangeTiling($blockRef, isBlockB, isBlockC, finishTile, index) {
          if (reOlapicTiles.length > 0) {
            clearBlock($blockRef);
            addNonOlapicTiles($blockRef, isBlockB, isBlockC);
            addOlapicTiles($blockRef, isBlockB);
            if (finishTile) {
              finishBlock($blockRef);
            }
            if (isBlockC && isMobile()) {
              finishBlock($blockRef);
            }
          } else {
            populateRemainingTiles($blockRef, index);
          }
        }

        function updateOlapicTilePositions($blockARef, $blockB1Ref, $blockB2Ref, $blockCRef) {
          // Block A
          rearangeTiling($blockARef, false, false, false, 4);
          // Block B1
          rearangeTiling($blockB1Ref, true, false, true, 6);
          // BLock B2
          rearangeTiling($blockB2Ref, true, false, true, 6);
          // Block C
          rearangeTiling($blockCRef, false, true, false, 4);
        }

        function resizeOlapicImagery() {
          var $olapicTiles = getOlapicTiles();

          $.each($olapicTiles, function (tile_index, tile_value) {
            var $olapicTile = $(tile_value),
              $currentImages = $olapicTile.find('picture'),
              $imageUsed = getImageUsed($currentImages),
              $imgtag = $imageUsed.find('img'),
              imgtagWidth = $imgtag.css('width');

            if (checkForOlapicTile($imgtag)) {
              $imgtag.siblings('.olapicimg').css('height', imgtagWidth);
            } else {
              $olapicTile.find('.js-olapicimg').css('height', imgtagWidth);
            }
          });
        }

        function setResizeListener() {
          $(window).on('resize', _.debounce(resizeOlapicImagery, 250));
        }

        /* Modify the Platform returned menu HTML to suit Discover */
        function renderMenuBar() {
          var $menuList = $menu.find('> ul');

          $menuList.addClass('discover-navigation__menu');
          var $menu_li = $menuList.find('> li');

          $menu_li.addClass('discover-navigation__step js-discover-navigation__step--tag');
          $.each($menu_li, function (li_index, li_value) {
            var $li = $(li_value);

            $li.find('ul').remove();
          });
          $module.addClass('showing');
        }

        /* Templates for lazy loading the next iteration of tiles */
        function getTemplates() {
          generic.template.get({
            path: '/sites/esteelauder/themes/estee_base/template_api/discover/discover_blocks_v1/discover_block_v1.mustache',
            forceReload: true,
            callback: function (html) {
              blockHtml = html;
              generic.template.get({
                path: '/sites/esteelauder/themes/estee_base/template_api/discover/discover_partials_v1/discover_block_partial_v1.mustache',
                forceReload: true,
                callback: function (partial) {
                  partialHtml = partial;
                }
              });
            }
          });
        }

        getTemplates();

        getOlapicTileData($blockA);
        getOlapicTileData($blockB1);
        getOlapicTileData($blockB2);
        getOlapicTileData($blockC);
        if (reOlapicTiles.length > 0) {
          updateOlapicTilePositions($blockA, $blockB1, $blockB2, $blockC);
        }
        getOlapicMarkup();
        renderMenuBar();
        reNoneOlapicTiles = [];
        function getOlapicMarkup() {
          generic.template.get({
            path: '/sites/esteelauder/themes/estee_base/template_api/discover/discover_blocks_v1/discover_olapic_viewer_v1.mustache',
            forceReload: true,
            callback: function (html) {
              olapicViewer = html;
              getOlapicData();
              setResizeListener();
            }
          });
        }

        /* Prcocess any full width tiles. Removing them from the standard tile rendering if found */
        function processFullWidthTiles(json_data, $allFullers, blockBMiddle, i) {
          if (
            json_data[i]['template_data'][0]['blogarticlemetadata']['blog_article_metadata'][
              'blog_article_metadata_tile_style'
            ] === 'full_width_tile'
          ) {
            var exists = false;

            for (var j = 0; j < $allFullers.length; j++) {
              var $fuller = $($allFullers[j]),
                fullerHref = $fuller.find('.discover-block__url').attr('href'),
                currentFullerHref =
                  json_data[i]['template_data'][0]['blogarticlemetadata']['blog_article_metadata'][
                    'blog_article_metadata_lp_url'
                  ];

              if (currentFullerHref !== '') {
                if (fullerHref.replace(' ', '') === currentFullerHref.replace(' ', '')) {
                  exists = true;
                }
              }
            }
            if (!exists) {
              blockBMiddle.push(
                json_data[i]['template_data'][0]['blogarticlemetadata']['blog_article_metadata']
              );
              blockBMiddle[0]['html_return_video_content'] =
                json_data[i]['template_data'][0]['videocontent']['video_content'];
              // do not render this again as a normal tile
              json_data.splice(i, 1);
            }

            return json_data;
          }

          return json_data;
        }

        // Process Block A tiles - first four
        function processBlockATiles(json_data, limits, i) {
          if (i < limits - 1) {
            blockA.push(
              json_data[i]['template_data'][0]['blogarticlemetadata']['blog_article_metadata']
            );
            blockA[i]['html_return_video_content'] =
              json_data[i]['template_data'][0]['videocontent']['video_content'];
          }
        }

        // Process Block B tiles - next six
        function processBlockBTiles(json_data, limits, i) {
          if (i >= limits - 1 && i < limits + 5) {
            blockB.push(
              json_data[i]['template_data'][0]['blogarticlemetadata']['blog_article_metadata']
            );
            blockB[i - (limits - 1)]['html_return_video_content'] =
              json_data[i]['template_data'][0]['videocontent']['video_content'];
          }
        }

        // Process Block B2 tiles - next six
        function processBlockB2Tiles(json_data, limits, i) {
          if (i >= limits + 5 && i < limits + 11) {
            blockBSecond.push(
              json_data[i]['template_data'][0]['blogarticlemetadata']['blog_article_metadata']
            );
            blockBSecond[i - (limits + 5)]['html_return_video_content'] =
              json_data[i]['template_data'][0]['videocontent']['video_content'];
          }
        }

        // Process Block C tiles - final four tiles
        function processBlockCTiles(json_data, limits, i) {
          if (i >= limits + 11 && i < limits + 15) {
            blockC.push(
              json_data[i]['template_data'][0]['blogarticlemetadata']['blog_article_metadata']
            );
            blockC[i - (limits + 11)]['html_return_video_content'] =
              json_data[i]['template_data'][0]['videocontent']['video_content'];
          }
        }

        /* Arrange the block data into the format expecting by the mustache bindings */
        function getBindings() {
          var bindings = {
            blog: {
              metadata: {
                blockA: blockA,
                blockB: blockB,
                blockBMiddle: blockBMiddle,
                blockBSecond: blockBSecond,
                blockC: blockC
              }
            }
          };

          return bindings;
        }

        function resetBlocks() {
          blockA = [];
          blockB = [];
          blockBMiddle = [];
          blockBSecond = [];
          blockC = [];
        }

        /* Loop through the json data and separate not the nodes into the approriate blocks */
        function getNextIteration(json_data) {
          var limits = 5;
          var $allFullers = $module.find('.discover-block.discover-block__b--middle');

          resetBlocks();
          for (var i = 0; i < json_data.length; i++) {
            // Full width tiles.
            json_data = processFullWidthTiles(json_data, $allFullers, blockBMiddle, i);
            // Block A - First four tiles.
            processBlockATiles(json_data, limits, i);
            // Block B1 - next 6 tiles.
            processBlockBTiles(json_data, limits, i);
            // Block B2 - next 6 tiles.
            processBlockB2Tiles(json_data, limits, i);
            // Block C - next 4 tiles.
            processBlockCTiles(json_data, limits, i);
          }

          return getBindings();
        }

        function getFilterTerm($select) {
          var selectedTerm = '';

          if (pageIndex > 0) {
            pathArray = pathArray.slice(0, pageIndex);
          }

          if (pathArray.length > 2) {
            selectedTerm = pathArray[pathArray.length - 1] || pathArray[pathArray.length - 2];
          }
          var $selectLabel = $select
            .siblings('.js-discover-filter__select')
            .children('.selectBox-label');

          if ($selectLabel && $selectLabel.length > 0) {
            $selectLabel.html(selectedTerm.replace(/-/g, ' '));
          }
        }

        $filter.find('.js-discover-filter__select').on('click', function () {
          $listContainer.toggleClass('hidden');

          if (!$listContainer.hasClass('hidden')) {
            $filterList.focus();
          }
        });

        $filterList.addClass(
          'selectBox-dropdown-menu selectBox-options discover-filter__select-selectBox-dropdown-menu js-discover-filter__select-selectBox-dropdown-menu'
        );
        getFilterTerm($listContainer);

        $('.js-discover-filter__select-selectBox-dropdown-menu')
          .find('li')
          .once()
          .on('click', function () {
            var $a = $(this).find('a');
            var promoName = $a.html();

            fireAnalytic(promoName, 'filter');
          });

        /* Extract the necessary terms from the url to generate the correct API call */
        function extractTerms(pathArray, apiUrl) {
          // Landing page
          if (pathArray.length === 2) {
            apiUrl += '/all/all';
          }
          // Category Page
          if (pathArray.length === 3) {
            apiUrl += '/' + pathArray[2] + '/all';
          }
          // Term Page
          if (pathArray.length === 4) {
            apiUrl += '/' + pathArray[2] + '/' + pathArray[3];
          }

          return apiUrl;
        }

        /* Move any Olapic tiles to the last tile in their block */
        function updateOlapicTiles() {
          var $blockAs = $module.find('.discover-block.discover-block__a');
          var $blockBs = $module.find('.discover-block.discover-block__b');
          var $blockCs = $module.find('.discover-block.discover-block__c');
          var $currentBlockA = $($blockAs[$blockAs.length - 1]);
          var $currentBlockB1 = $($blockBs[$blockBs.length - 2]);
          var $currentBlockB2 = $($blockBs[$blockBs.length - 1]);

          $currentBlockC = $($blockCs[$blockCs.length - 1]);

          if ($currentBlockA.length > 0) {
            getOlapicTileData($currentBlockA);
          }
          if ($currentBlockB1.length > 0) {
            getOlapicTileData($currentBlockB1);
          }
          if ($currentBlockB2.length > 0) {
            getOlapicTileData($currentBlockB2);
          }
          if ($currentBlockC.length > 0) {
            getOlapicTileData($currentBlockC);
          }
          if (reOlapicTiles.length > 0) {
            updateOlapicTilePositions(
              $currentBlockA,
              $currentBlockB1,
              $currentBlockB2,
              $currentBlockC
            );
          }
          reNoneOlapicTiles = [];
        }

        /* Dynaically generated video tiles will need to have their event listeners set */
        function refreshVideoPlayersListeners($block) {
          var $youTubers = $block.find('.js-ytPlayer__icon, .js-ytPlayer__copy');

          if ($youTubers && $youTubers.length > 0) {
            $.event.trigger({
              type: 'yt_dynamic_player:apply_listeners',
              payload: $youTubers
            });
          }
          var $videoElem = $block.find('.video_content_v1');
          var $playElems = $videoElem.find(
            '.video_content_cta, .video_content_cta_link__underline'
          );

          if ($playElems && $playElems.length > 0) {
            $.event.trigger({
              type: 'video_content_v1:apply_listeners',
              payload: $playElems,
              videoEl: $videoElem
            });
          }
        }

        /* Rearrange any olapic tiles and update listeners */
        function updateLandingPage($block) {
          // Allow one second before turning off the spinner. Gives a more fluid feel.
          setTimeout(function () {
            updateOlapicTiles();
            getOlapicData($block);
            $currentSpinner.removeClass('showing');
            $block.append($currentSpinner);
            $block.removeClass('hiding');

            refreshVideoPlayersListeners($block);
            setupAnalytics();
            targetMet = false;
          }, 1000);
        }

        /* Call to API to get next set of node data and refresh the bindings accordingly */
        function renderNextIteration() {
          var apiUrl = '/elc_api/blog',
            pathName = window.location.pathname,
            tileCount = 20;

          apiUrl = extractTerms(pathName.split('/'), apiUrl);
          tileCount = tileCount - reOlapicTiles.length;
          tileIteration++;
          if (tileIteration > 1) {
            lastIterationCount = lastIterationCount + lastTileCount;
          }
          lastTileCount = tileCount;
          apiUrl += '/' + lastTileCount + '/' + lastIterationCount + '/';
          $.getJSON(apiUrl, function (json_data) {
            if (json_data.length > 0) {
              var partialObj = {
                discover_block_partial_v1: partialHtml
              };
              var bindings = getNextIteration(json_data);
              var rendered = Mustache.render(blockHtml, bindings, partialObj);
              var $rendered = $(rendered);
              var $block = $rendered.find('.js-discover-block');

              $block.addClass('hiding');
              var $originalBlocks = $module.find('.js-discover-block');
              var $originalBlock = $($originalBlocks[$originalBlocks.length - 1]);

              $block.insertAfter($originalBlock);

              // Allow one second before turning off the spinner. Gives a more fluid feel.
              updateLandingPage($block);
            } else {
              $currentSpinner.removeClass('showing');
            }
          });
        }

        /* Engagement analytics */
        function fireAnalytic(promoName, placementType) {
          if (site.track) {
            site.track.evtLink({
              event_name: 'navigation_click',
              enh_action: 'promo_click',
              event_action: 'promotion click',
              event_category: 'ecommerce',
              event_label: "banner click | ['" + promoName + "']",
              promo_creative: ['' + placementType + ''],
              promo_id: ['banner-' + placementType + '-' + promoName + ''],
              promo_name: ['' + promoName + ''],
              promo_pos: ['banners']
            });
          }
        }

        /* Set up the analytic click events for the tiles.
         * This is need to be run each time a lazy load is done
         */
        function setupAnalytics() {
          var $tiles = $thismodule.find('.js-discover-block__container');

          $tiles.once().on('click', function () {
            var promoName = $(this).find('.discover-block__header').html();

            fireAnalytic(promoName, 'tile');
          });
          $tiles
            .find('.discover-block__subtags')
            .find('li')
            .once()
            .on('click', function () {
              var promoName = $(this).find('a').html();

              fireAnalytic(promoName, 'tag');
            });
          $menu
            .find('.js-discover-navigation__step--tag')
            .once()
            .on('click', function () {
              var promoName = $(this).find('a').html();

              fireAnalytic(promoName, 'navigation');
            });
        }

        // Mobile check
        function isMobile() {
          if (Unison.fetch.now().name === 'small') {
            return true;
          }

          return false;
        }

        setupAnalytics();

        if (Number(page) >= 1) {
          if (page !== '1') {
            /* SEO Update canonical tag for pages 2 and above */
            let href = document.location.href;

            if (href && href.match(/^https?:\/\/m/u)) {
              // Remove "m." for lower env
              href = href.replace('://m.e.', '://e.');
              // Replace "m" with "www" for PROD & Pre-PROD
              href = href.replace('://m.', '://www.');
              href = href.replace('://mtmp.', '://wwwtmp.');
            }
            $('link[rel=canonical]').attr('href', href);
          }

          /* Skip scroll listener for valid paginated pages */
          return;
        }

        /* Lazy load scrolling */
        $(window).on(
          'scroll',
          _.debounce(function () {
            if (targetMet === false) {
              if ($(this).scrollTop() >= $currentBlockC.offset().top) {
                targetMet = true;
                $currentSpinner.addClass('showing');
                renderNextIteration();
              }
            }
          })
        );
      });
    }
  };
})(jQuery, SelectBox);
